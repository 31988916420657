<template>
  <div class="jxyh-flex-v carousel-box">
    <div class="left-btn jxyh-flex-v" @click="moveLeft">
      <i class="el-icon-caret-left icon"></i>
    </div>
    <div ref="group" class="carousel-box-content">
      <div class="jxyh-flex-v group">
        <span v-for="(item, index) in list" :key="index" class="jxyh-flex-h item" :style="{ opacity:  notOpacity ? 1 : opacity(item.stageSituation)}">
          <el-image class="img" :src="iconList[item.stageSituation]"></el-image>
          <div class="title">{{ item.stage }}</div>
          <slot name="customSlot" :row="item"></slot>
        </span>
      </div>
    </div>
    <div class="right-btn jxyh-flex-v" @click="moveRight">
      <i class="el-icon-caret-right icon"></i>
    </div>
  </div>
</template>

<script>
import icon_suss from '@/view/jxyh/assets/icons/icon_suss.png'
import icon_ing from '@/view/jxyh/assets/icons/icon_ing.png'
import icon_jian from '@/view/jxyh/assets/icons/icon_jian.png'

export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    notOpacity:{
      type: Boolean,
      default: false
    },
    moveUnit: {
      type: Number,
      default: 165
    }
  },
  data() {
    return {
      iconList: [icon_suss, icon_ing, icon_jian],
      currentIndex: 0,
    }
  },
  methods: {
    moveLeft() {
      if (this.currentIndex) {
        this.currentIndex--
        this.$refs.group.scrollTo({
          left: this.moveUnit * this.currentIndex,
          behavior: 'smooth'
        })
      }
    },
    moveRight() {
      if (this.currentIndex < this.list.length - 4) {
        this.currentIndex++
        this.$refs.group.scrollTo({
          left: this.moveUnit * this.currentIndex,
          behavior: 'smooth'
        })
      }
    },
    opacity(type) {
      switch (type) {
        case 0:
          return 0.7
        case 1:
          return 1
        default:
          return 0.4
      }
    }
  }
}
</script>

<style lang="less" scoped>
.carousel-box {
  &-content {
    width: 651px;
    height: 141px;
    overflow: hidden;
    .group {
      width: max-content;
      gap: 9px;
      .item {
        gap: 12px;
        width: 156px;
        height: 141px;
        background: url('../../assets/images/img_lcb.png');
        .img{
          width: 32px;
          height: 32px;
        }
        .title{
          width: 100%;
          padding: 0 6px;
          box-sizing: border-box;
          font-size: 16px;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          line-clamp: 1;
          white-space: nowrap;
          color: #ffffff;
        }
      }
    }
  }
  .left-btn {
    width: 34px;
    height: 34px;
    background: linear-gradient(143deg, rgba(28, 47, 99, 0) 0%, rgba(0, 233, 255, 0.46) 100%);
    border: 1px solid;
    border-image: linear-gradient(135deg, rgba(24, 123, 205, 0), rgba(51, 216, 250, 1)) 1 1;
    margin-right: 20px;
  }
  .right-btn {
    width: 34px;
    height: 34px;
    background: linear-gradient(143deg, rgba(28, 47, 99, 0) 0%, rgba(0, 233, 255, 0.46) 100%);
    border: 1px solid;
    border-image: linear-gradient(135deg, rgba(24, 123, 205, 0), rgba(51, 216, 250, 1)) 1 1;
    margin-left: 20px;
  }
  .icon {
    font-size: 20px;
    background: linear-gradient(9deg, #38d4dd 0%, #19fbff 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>